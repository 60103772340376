import { IdentifiableWithName } from 'src/shared/types'

export const getReadonlyArrayValues = <T extends IdentifiableWithName>(
  payload: T[] = [],
  activeIds: Array<number | string> = [],
) => {
  if (!payload.length || !activeIds.length) {
    return ''
  }

  return payload
    .filter(({ id }) => activeIds.includes(id))
    .map(({ name }) => name)
    .join(', ')
}

export const getBooleanText = (payload: boolean | string): 'common.yes' | 'common.no' => {
  if (typeof payload === 'string') {
    const isBooleanText = payload === 'true'
    return isBooleanText ? 'common.yes' : 'common.no'
  }

  return payload ? 'common.yes' : 'common.no'
}
