import { Card, Group, Text } from '@mantine/core'
import { Link, LinkProps } from 'react-router-dom'

import { Icon } from 'src/shared/ui-kit/icon'

import classes from './report-card-link.module.css'

type ReportCardLinkProps = {
  title: string
} & Omit<LinkProps, 'className'>

export function ReportCardLink({ title, ...linkProps }: ReportCardLinkProps) {
  return (
    <Card className={classes.reportCard} component={Link} {...linkProps}>
      <Group justify="space-between" gap={8} wrap="nowrap">
        <Text className={classes.reportCard__text}>{title}</Text>

        <Icon name="arrow-right" size={24} className={classes.reportCard__icon} />
      </Group>
    </Card>
  )
}
